import * as z from "zod"

export const StatusChangeDbModel = z.object({
  id: z.bigint(),
  workspaceId: z.string(),
  actorId: z.string(),
  tableName: z.string(),
  timestamp: z.coerce.date(),
  status: z.string(),
  startedAt: z.date(),
  counts: z.number().int(),
  description: z.string().nullish(),
  queueSize: z.number().int(),
})
